.research-container {
    padding: 60px 20px 20px 20px;
    color: white;
    text-align: left;
}

.research-container h2 {
    margin-bottom: 20px;
    font-size: 2rem;
}

.poster-gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.research-poster {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.research-poster:hover {
    transform: scale(1.05);
}

/* New blur effect for the last poster */
.blurred-poster {
    filter: blur(5px); /* Adjust the intensity if needed */
}
