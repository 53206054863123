.card {
    text-align: center;
    width: 200px; /* Set a fixed width */
    height: 100px; /* Set a fixed height */
    padding: 20px;
    margin: 20px;
    border: 1px solid #555;
    border-radius: 8px;
    /* background-color: #333; */
    background-color: rgba(51, 51, 51, 0.5); /* Increased transparency (0.5) */
    color: #fff;
  }