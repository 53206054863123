/* Original Project Styles */
body {
  background-color: #121212;
  color: #ffffff;
  margin: 0;
  font-family: Arial, sans-serif;
}

.projects {
  padding: 4rem 2rem 2rem 2rem; /* Added more padding at the top */
  text-align: center;
  background-color: #121212;
  min-height: 100vh;
}

.projects h2 {
  color: #ffffff;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  max-width: 1200px; /* Adjust as needed */
  margin: 0 auto;
  gap: 2rem;
}

.project-card {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  height: 350px; /* Adjusted card height */
  transition: transform 0.3s, box-shadow 0.3s;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: opacity 0.3s;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
  border-radius: 10px;
}

.project-card:hover .overlay {
  opacity: 1;
}

.project-title {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin: 0;
  padding: 1rem;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #1e1e1e;
  color: #ffffff;
  padding: 20px;
  width: 90%;
  max-width: 700px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.modal-content h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.modal-content p {
  font-size: 1rem;
  line-height: 1.6;
}

.project-description {
  padding-top: 20px;  /* Space between the image and the description */
  padding-bottom: 20px; /* Adds space below the description */
  font-size: 1rem;
  line-height: 1.6;
}

/* Link styling for modal content */
.modal-content a {
  color: #1E90FF; /* Customize link color */
  text-decoration: none;
  font-weight: bold;
}

.modal-content a:hover {
  color: #63B8FF; /* Hover color */
  text-decoration: underline;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 1.2em;
  background: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
}

.carousel .slide img {
  width: 100%;
  height: auto;
}
