/* EmploymentList.css */
.employment-list {
    padding: 2rem;
    background-color: #121212;
    color: #ffffff;
    font-family: Arial, sans-serif;
  }
  
  .employment-list h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .employment-list-items {
    list-style-type: none;
    padding: 0;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .employment-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #333;
    padding: 1rem 0;
  }
  
  .logo-container {
    flex-shrink: 0;
    margin-right: 1.5rem;
    background-color: #121212;
    padding: 5px;
    border-radius: 5px;
  }
  
  .company-logo {
    width: 80px;
    height: 80px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 5px;
  }
  
  .employment-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .job-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #ddd;
  }
  
  .employment-dates {
    font-size: 1rem;
    color: #bbb;
    text-align: right;
  }
  