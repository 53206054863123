.master-container {
  padding-left: 80px;
  padding-right: 80px;
  background-color: black;
}

.header {
  padding-top: 80px;
  text-align: left;
  margin-bottom: 10px;
  color: white;
}

.header h3 {
  font-size: 1.5em; /* Increase subtitle size */
  margin-bottom: 10px;
}

.header h1 {
  font-size: 3em; /* Increase main title size */
}

.divided-container {
  display: flex;
  align-items: center;
  text-align: left;
  background-color: black;
  color: white;
  margin: auto;
  padding-top: 0px;
}

.picture,
.content {
  flex: 0 0 30%; /* Set the picture to take up 30% of the container width */
  padding: 10px;
  background-color: black;
}

.content {
  flex: 1; /* Set the content to take up the remaining space */
  padding-right: 10px;
  font-size: 1.8em; /* Increase content text size */
}

.picture img {
  width: 100%;
  height: auto;
}

/* Reduce spacing between picture and content */
.picture {
  margin-right: 5px;
}

.links-container {
  display: flex; /* divides the container into 2 columns */
  justify-content: space-between;
  background-color: black;
}

.links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.links li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 0.8em; /* Increase link text size */
}

.link {
  margin-right: 5px; /* Adjust spacing between the icon and text if needed */
}

/* Style the icon (adjust as needed) */
.link::before {
  content: '\2022'; /* Unicode character for a bullet point */
  font-size: 1.5em; /* Adjust the size of the icon */
  margin-right: 5px; /* Adjust spacing between the icon and text if needed */
}

.statsbar {
  display: flex;
  justify-content: space-around;
  flex-direction: row; /* Set the direction to row */
}

.skills-section {
  color: white;
  font-size: 1.2em; /* Increase font size for skills section */
}

.skills-header {
  text-align: left;
}
