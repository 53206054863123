.navbar {
    background-color: black;
    color: white;
    display: flex;
    justify-content: right; /* horizontal alignment */
    align-items: center;  /* vertical alignment */
    height: 80px;
    width: 100vw;
    position: fixed;
    /*an element with higher z-index will appear in front of an elemnt with lower z-index */
    z-index: 10; /* controls overlapping elements */ 
}
  
.menuItems a {
    background-color: black;
    font-weight: bold;
    color: white;
    text-decoration: none;
    padding: 0 20px;
}

.menuItems a:hover,
.menuItems a.active {
    color: #5D3FD3;
}

.menuItems{
  display: flex;
  justify-content: space-between;
  padding: 16px;
  flex: auto;
}

nav {
  display: flex;
  /* flex: auto; */
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.name{
    justify-content: left;
    background-color: black;
    font-size: 30px;
}