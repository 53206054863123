/* TickerBar.css */

.ticker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #121212;
  padding: 1rem;
  overflow: hidden;
  width: 100%;
}

.press-heading {
  color: #ffffff;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.ticker-row {
  display: flex;
  gap: 2rem; /* Adjust spacing between logos */
}

.press-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.press-logo {
  height: 50px; /* Adjust as needed */
  width: auto;
}
